.member_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 15px;
}

.member_avatar {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 50%;
}

.member_name {
  color: #141D49 !important;
  font-size: 16px;
  font-weight: 700;
}

.member_email {
  color: #141D49 !important;
  font-size: 11px;
  font-weight: 400;
}