.group_name {
  font-weight: 700;
  font-size: 15px;
}

.group_number_of_members {
  font-weight: 400;
  font-size: 11px;
}

.action_color {
  color: #009BE5;
}

.action_color:hover {
  color: #009BE599
}

.action_modify {
  cursor: pointer;
  margin-left: 15px;
}

.action_delete {
  margin-left: 15px;
  cursor: pointer;
  color: #ad0000;
}

.action_delete:hover {
  color: #ad000099;
}