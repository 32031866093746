.card_title {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
}

.card_number {
  margin: 15px 0;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: '#FFFFFF';
}