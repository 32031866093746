.organization_item_wrapper {
  margin-bottom: 20px;
  justify-content: space-between;
}

.organization_info {
  align-items: center;
}

.organization_logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.organization_name {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
}

.org_num_of_mem {
  font-size: 11px;
  font-weight: 400;
}

.action_color {
  color: #009BE5;
}

.action_color:hover {
  color: #009BE599
}

.action_modify {
  cursor: pointer;
  margin-left: 15px;
}

.action_delete {
  margin-left: 15px;
  cursor: pointer;
  color: #ad0000;
}

.action_delete:hover {
  color: #ad000099;
}