.organization_info_card {
  max-width: 500px;
}

.card_content_label {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 3px;
}

.delete_organization_button {
  margin: 20px 0 0 20px;
  color: #141D49;
  font-weight: 700;
  cursor: pointer;
  width: max-content;
}

.list_card {
  min-width: 400px;
  max-width: 600px;
}